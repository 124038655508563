import axios from 'axios';
import config from './config';

const instance = axios.create({
  baseURL: config.apiUrl,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  console.log("Token: ", token);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
    console.log(config.headers['Authorization']);
  }
  return config;
});

export default instance;
