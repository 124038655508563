import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance'; // Stellen Sie sicher, dass die Axios-Instanz verwendet wird
import config from '../config';
import '../css/Sidebar.css';

const Sidebar = ({ onSelectWeek, onNavigateToRecipes }) => {
    const [weeks, setWeeks] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [upcomingFridays, setUpcomingFridays] = useState([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [weekToDelete, setWeekToDelete] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const weeksPerPage = 5;

    useEffect(() => {
        fetchWeeks();
        calculateUpcomingFridays();
    }, []);

    const fetchWeeks = async () => {
        try {
            const response = await axiosInstance.get('/weeks.php'); // Verwenden Sie die Axios-Instanz
            console.log(response.data);
            if (Array.isArray(response.data)) {
                setWeeks(response.data);
            } else {
                console.error("Invalid API response, expected an array");
                setWeeks([]);
            }
        } catch (error) {
            console.error("Error fetching weeks:", error);
            setWeeks([]);
        }
    };

    const calculateUpcomingFridays = () => {
        const fridays = [];
        let date = new Date();
        while (fridays.length < 10) {
            date.setDate(date.getDate() + (5 - date.getDay() + 7) % 7 + 1);
            fridays.push(new Date(date));
        }
        setUpcomingFridays(fridays);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSave = async () => {
        try {
            const response = await axiosInstance.post('/weeks.php', { start_date: selectedDate });
            setWeeks([...weeks, response.data]);
            handleClose();
        } catch (error) {
            console.error("Error saving week:", error);
        }
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const getWeekNumber = (date) => {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };

    const formatWeekLabel = (dateString) => {
        const date = new Date(dateString);
        const weekNumber = getWeekNumber(date);
        return `KW ${weekNumber} - ${formatDate(date)}`;
    };

    const handleDelete = (week) => {
        setWeekToDelete(week);
        setShowDeleteConfirmation(true);
    };

    const confirmDelete = async () => {
        try {
            await axiosInstance.delete(`/weeks.php?id=${weekToDelete.id}`);
            setWeeks(weeks.filter(w => w.id !== weekToDelete.id));
            setShowDeleteConfirmation(false);
            setWeekToDelete(null);
        } catch (error) {
            console.error("Error deleting week:", error);
        }
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Get current weeks
    const indexOfLastWeek = currentPage * weeksPerPage;
    const indexOfFirstWeek = indexOfLastWeek - weeksPerPage;
    const currentWeeks = weeks.slice(indexOfFirstWeek, indexOfLastWeek);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(weeks.length / weeksPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <>
            <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <h2>Wochen</h2>
                <ul>
                    {currentWeeks.map(week => (
                        <li key={week.id} className="week-item">
                            <span onClick={() => onSelectWeek(week.id)}>{formatWeekLabel(week.start_date)}</span>
                            <button className="delete-button" onClick={() => handleDelete(week)}>✖</button>
                        </li>
                    ))}
                </ul>
                {weeks.length > weeksPerPage && (
                    <nav>
                        <ul className="pagination">
                            {pageNumbers.map(number => (
                                <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                                    <a onClick={() => paginate(number)} href="#!" className="page-link">
                                        {number}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                )}
                <button className="btn btn-primary" onClick={handleShow}>
                    Neue Woche erstellen
                </button>
                <button className="btn btn-secondary mt-2" onClick={onNavigateToRecipes}>
                    Rezepte verwalten
                </button>
            </div>
            <button className="toggle-button" onClick={toggleSidebar}>
                {isSidebarOpen ? '❮' : '❯'}
            </button>

            {show && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Neue Woche erstellen</h5>
                                <button type="button" className="btn-close" onClick={handleClose}></button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="formStartDate">Startdatum</label>
                                    <select
                                        className="form-control"
                                        id="formStartDate"
                                        value={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                    >
                                        <option value="">Wähle einen Freitag</option>
                                        {upcomingFridays.map((friday, index) => (
                                            <option key={index} value={friday.toISOString().split('T')[0]}>
                                                {formatDate(friday)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                    Abbrechen
                                </button>
                                <button type="button" className="btn btn-success" onClick={handleSave}>
                                    Speichern
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showDeleteConfirmation && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Woche löschen</h5>
                                <button type="button" className="btn-close" onClick={() => setShowDeleteConfirmation(false)}></button>
                            </div>
                            <div className="modal-body">
                                <p>Bist du sicher, dass du die Woche vom {formatWeekLabel(weekToDelete?.start_date)} löschen möchtest?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowDeleteConfirmation(false)}>
                                    Abbrechen
                                </button>
                                <button type="button" className="btn btn-danger" onClick={confirmDelete}>
                                    Löschen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Sidebar;
