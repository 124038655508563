import React, { useState, useEffect } from 'react';
import axios from '../axiosInstance';
import config from '../config';
import '../css/EditWeek.css';

const daysOfWeek = ['Freitag', 'Samstag', 'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag'];

const EditWeek = ({ weekId, onSave, recipes }) => {
    const [meals, setMeals] = useState([]);
    const [products, setProducts] = useState([]);
    const [filteredRecipes, setFilteredRecipes] = useState([]);
    const [currentInputIndex, setCurrentInputIndex] = useState(null);

    useEffect(() => {
        axios.get(`${config.apiUrl}/meals.php?week_id=${weekId}`)
            .then(response => {
                const fetchedMeals = response.data;
                const updatedMeals = daysOfWeek.map(day => {
                    const meal = fetchedMeals.find(m => m.day === day) || { day, meal: '' };
                    return { ...meal, week_id: weekId };
                });
                setMeals(updatedMeals);
            })
            .catch(error => console.error("Es gab einen Fehler beim Abrufen der Mahlzeiten:", error));
        
        axios.get(`${config.apiUrl}/products.php?week_id=${weekId}`)
            .then(response => setProducts(response.data))
            .catch(error => console.error("Es gab einen Fehler beim Abrufen der Produkte:", error));
    }, [weekId]);

    const handleMealChange = (index, value) => {
        const updatedMeals = [...meals];
        updatedMeals[index].meal = value;
        setMeals(updatedMeals);

        setCurrentInputIndex(index);

        if (value.length >= 3) {
            const matchingRecipes = recipes.filter(recipe =>
                recipe.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredRecipes(matchingRecipes);
        } else {
            setFilteredRecipes([]);
        }
    };

    const handleRecipeSelect = (index, recipe) => {
        const updatedMeals = [...meals];
        const mealId = updatedMeals[index].id;
        updatedMeals[index].meal = recipe.name;
        setMeals(updatedMeals);
        setFilteredRecipes([]);
        setCurrentInputIndex(null);

        const newProducts = recipe.products.map(product => ({
            week_id: weekId,
            meal_id: mealId || null,
            product_name: product.product_name,
            quantity: product.quantity
        }));
        setProducts([...products.filter(product => product.meal_id !== mealId), ...newProducts]);
    };

    const handleProductChange = (index, field, value) => {
        const updatedProducts = [...products];
        updatedProducts[index][field] = value;
        setProducts(updatedProducts);
    };

    const handleDeleteMeal = (mealId) => {
        axios.delete(`${config.apiUrl}/meals.php?id=${mealId}`)
            .then(() => {
                const updatedMeals = meals.map(meal =>
                    meal.id === mealId ? { ...meal, meal: '' } : meal
                );
                setMeals(updatedMeals);
                setProducts(products.filter(product => product.meal_id !== mealId));
            })
            .catch(error => console.error("Es gab einen Fehler beim Löschen der Mahlzeit:", error));
    };

    const handleSave = () => {
        Promise.all(meals.map(meal => {
            if (meal.id) {
                return axios.put(`${config.apiUrl}/meals.php?id=${meal.id}`, meal);
            } else if (meal.meal) {
                return axios.post(`${config.apiUrl}/meals.php`, meal)
                    .then(response => {
                        const newMealId = response.data.id;
                        const updatedProducts = products.map(product => {
                            if (product.meal_id === null && product.product_name) {
                                return { ...product, meal_id: newMealId };
                            }
                            return product;
                        });
                        setProducts(updatedProducts);

                        return Promise.all(updatedProducts.map(product =>
                            product.meal_id === newMealId
                            ? axios.post(`${config.apiUrl}/products.php`, product)
                            : Promise.resolve()
                        ));
                    });
            }
            return Promise.resolve();
        })).then(onSave).catch(error => console.error("Es gab einen Fehler beim Speichern der Änderungen:", error));
    };

    return (
        <div className="container">
            <h2>Woche bearbeiten</h2>
            <h3>Geplante Gerichte</h3>
            {meals.map((meal, index) => (
                <div className="form-group" key={index}>
                    <label>{meal.day}</label>
                    <input
                        type="text"
                        className="form-control"
                        value={meal.meal}
                        onChange={(e) => handleMealChange(index, e.target.value)}
                        onFocus={() => setCurrentInputIndex(index)}
                    />
                    {currentInputIndex === index && filteredRecipes.length > 0 && (
                        <ul className="autocomplete-results">
                            {filteredRecipes.map(recipe => (
                                <li
                                    key={recipe.id}
                                    onClick={() => handleRecipeSelect(index, recipe)}
                                    className="autocomplete-item"
                                >
                                    {recipe.name}
                                </li>
                            ))}
                        </ul>
                    )}
                    {meal.id && (
                        <button className="btn btn-danger" onClick={() => handleDeleteMeal(meal.id)}>Löschen</button>
                    )}
                </div>
            ))}
            <h3>Einzukaufende Produkte</h3>
            {products.map((product, index) => (
                <div key={index}>
                    <div className="product-group">
                        <div className="form-group">
                            <label>Produkt</label>
                            <input
                                type="text"
                                className="form-control"
                                value={product.product_name}
                                onChange={(e) => handleProductChange(index, 'product_name', e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Menge</label>
                            <input
                                type="text"
                                className="form-control"
                                value={product.quantity}
                                onChange={(e) => handleProductChange(index, 'quantity', e.target.value)}
                            />
                        </div>
                    </div>
                    {index < products.length - 1 && <hr />}
                </div>
            ))}
            <button className="btn btn-success mt-2" onClick={handleSave}>Speichern</button>
        </div>
    );
};

export default EditWeek;
