import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Login from './components/Login';
import MainApp from './MainApp'; // Your main application component

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            // Verify the token with the backend if necessary
            setIsAuthenticated(true);
        }
    }, []);

    const handleLogin = () => {
        setIsAuthenticated(true);
    };

    return (
        <div className="App">
            {isAuthenticated ? <MainApp /> : <Login onLogin={handleLogin} />}
        </div>
    );
};

export default App;
