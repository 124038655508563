import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import WeekView from './components/WeekView';
import EditWeek from './components/EditWeek';
import RecipePage from './components/RecipePage';
import axios from './axiosInstance';
import config from './config';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Sidebar.css';
import './css/WeekView.css';
import './css/EditWeek.css';
import './css/RecipePage.css';
import './css/App.css';

const App = () => {
    const [currentWeekId, setCurrentWeekId] = useState(null);
    const [editing, setEditing] = useState(false);
    const [view, setView] = useState('week'); // 'week' or 'recipes'
    const [recipes, setRecipes] = useState([]);

    useEffect(() => {
        axios.get(`${config.apiUrl}/weeks.php`)
            .then(response => {
                console.log(response.data);
                if (response.data.length > 0) {
                    setCurrentWeekId(response.data[0].id);
                }
            })
            .catch(error => console.error(error));
        
        axios.get(`${config.apiUrl}/recipes.php`)
            .then(response => {
                setRecipes(response.data);
            })
            .catch(error => console.error(error));
    }, []);

    const handleSelectWeek = (weekId) => {
        setCurrentWeekId(weekId);
        setEditing(false);
        setView('week');
    };

    const handleEditWeek = () => {
        setEditing(true);
    };

    const handleSaveWeek = () => {
        setEditing(false);
    };

    const handleNavigateToRecipes = () => {
        setView('recipes');
    };

    return (
        <div className="app">
            <Sidebar onSelectWeek={handleSelectWeek} onNavigateToRecipes={handleNavigateToRecipes} />
            <div className={`main-content ${editing ? 'editing' : ''}`}>
                {view === 'week' && currentWeekId && !editing && (
                    <WeekView weekId={currentWeekId} onEditWeek={handleEditWeek} />
                )}
                {view === 'week' && currentWeekId && editing && (
                    <EditWeek weekId={currentWeekId} onSave={handleSaveWeek} recipes={recipes} />
                )}
                {view === 'recipes' && (
                    <RecipePage />
                )}
            </div>
        </div>
    );
};

export default App;
