import React, { useState, useEffect } from 'react';
import axios from '../axiosInstance';
import config from '../config';
import '../css/RecipePage.css';

const RecipePage = () => {
    const [recipes, setRecipes] = useState([]);
    const [newRecipe, setNewRecipe] = useState({ name: '', products: [{ product_name: '', quantity: '' }] });
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [recipeToDelete, setRecipeToDelete] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const recipesPerPage = 5;

    useEffect(() => {
        fetchRecipes();
    }, []);

    const fetchRecipes = () => {
        axios.get(`${config.apiUrl}/recipes.php`)
            .then(response => setRecipes(response.data))
            .catch(error => console.error('Fehler beim Abrufen der Rezepte:', error));
    };

    const handleRecipeChange = (field, value) => {
        setNewRecipe({ ...newRecipe, [field]: value });
    };

    const handleProductChange = (index, field, value) => {
        const updatedProducts = [...newRecipe.products];
        updatedProducts[index][field] = value;
        setNewRecipe({ ...newRecipe, products: updatedProducts });
    };

    const handleAddProductField = () => {
        setNewRecipe({ ...newRecipe, products: [...newRecipe.products, { product_name: '', quantity: '' }] });
    };

    const handleSaveRecipe = () => {
        axios.post(`${config.apiUrl}/recipes.php`, newRecipe)
            .then(response => {
                setRecipes([...recipes, response.data]);
                setNewRecipe({ name: '', products: [{ product_name: '', quantity: '' }] });
            })
            .catch(error => console.error('Fehler beim Hinzufügen des Rezepts:', error));
    };

    const handleDelete = (recipe) => {
        setRecipeToDelete(recipe);
        setShowDeleteConfirmation(true);
    };

    const confirmDelete = () => {
        axios.delete(`${config.apiUrl}/recipes.php?id=${recipeToDelete.id}`)
            .then(() => {
                setRecipes(recipes.filter(r => r.id !== recipeToDelete.id));
                setShowDeleteConfirmation(false);
                setRecipeToDelete(null);
            })
            .catch(error => console.error('Fehler beim Löschen des Rezepts:', error));
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Get current recipes
    const indexOfLastRecipe = currentPage * recipesPerPage;
    const indexOfFirstRecipe = indexOfLastRecipe - recipesPerPage;
    const currentRecipes = recipes.slice(indexOfFirstRecipe, indexOfLastRecipe);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(recipes.length / recipesPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="container">
            <h2>Rezepte</h2>
            <ul className="list-group mb-3">
                {currentRecipes.map(recipe => (
                    <li key={recipe.id} className="list-group-item recipe-item">
                        <div className="recipe-header">
                            <h5>{recipe.name}</h5>
                            <button className="delete-button" onClick={() => handleDelete(recipe)}>✖</button>
                        </div>
                        <ul>
                            {recipe.products.map(product => (
                                <li key={product.id}>{product.product_name} ({product.quantity})</li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
            <nav>
                <ul className="pagination">
                    {pageNumbers.map(number => (
                        <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <a onClick={() => paginate(number)} href="#!" className="page-link">
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
            <div className="form-group">
                <label htmlFor="recipeName">Rezeptname</label>
                <input
                    type="text"
                    className="form-control"
                    id="recipeName"
                    value={newRecipe.name}
                    onChange={(e) => handleRecipeChange('name', e.target.value)}
                />
            </div>
            {newRecipe.products.map((product, index) => (
                <div className="form-group" key={index}>
                    <label>Produktname</label>
                    <input
                        type="text"
                        className="form-control"
                        value={product.product_name}
                        onChange={(e) => handleProductChange(index, 'product_name', e.target.value)}
                    />
                    <label>Menge</label>
                    <input
                        type="text"
                        className="form-control"
                        value={product.quantity}
                        onChange={(e) => handleProductChange(index, 'quantity', e.target.value)}
                    />
                </div>
            ))}
            <button className="btn btn-secondary" onClick={handleAddProductField}>Produkt hinzufügen</button>
            <button className="btn btn-success mt-2" onClick={handleSaveRecipe}>Rezept speichern</button>

            {showDeleteConfirmation && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Rezept löschen</h5>
                                <button type="button" className="btn-close" onClick={() => setShowDeleteConfirmation(false)}></button>
                            </div>
                            <div className="modal-body">
                                <p>Bist du sicher, dass du das Rezept "{recipeToDelete?.name}" löschen möchtest?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowDeleteConfirmation(false)}>
                                    Abbrechen
                                </button>
                                <button type="button" className="btn btn-danger" onClick={confirmDelete}>
                                    Löschen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RecipePage;
