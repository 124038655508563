import React, { useState, useEffect } from 'react';
import axios from '../axiosInstance';
import config from '../config';
import '../css/WeekView.css';

const daysOfWeek = ['Freitag', 'Samstag', 'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag'];

const WeekView = ({ weekId, onEditWeek }) => {
    const [meals, setMeals] = useState([]);
    const [products, setProducts] = useState([]);
    const [newProduct, setNewProduct] = useState({ product_name: '', quantity: '' });

    useEffect(() => {
        axios.get(`${config.apiUrl}/meals.php?week_id=${weekId}`)
            .then(response => {
                const fetchedMeals = response.data;
                const updatedMeals = daysOfWeek.map(day => {
                    const meal = fetchedMeals.find(m => m.day === day) || { day, meal: '' };
                    return { ...meal, week_id: weekId };
                });
                setMeals(updatedMeals);
            })
            .catch(error => console.error("Es gab einen Fehler beim Abrufen der Mahlzeiten:", error));
        
        axios.get(`${config.apiUrl}/products.php?week_id=${weekId}`)
            .then(response => setProducts(response.data))
            .catch(error => console.error("Es gab einen Fehler beim Abrufen der Produkte:", error));
    }, [weekId]);

    const handleAddProduct = () => {
        if (!newProduct.product_name || !newProduct.quantity) {
            alert("Bitte sowohl Produktname als auch Menge angeben");
            return;
        }
        axios.post(`${config.apiUrl}/products.php`, { ...newProduct, week_id: weekId })
            .then(response => {
                setProducts([...products, response.data]);
                setNewProduct({ product_name: '', quantity: '' });
            })
            .catch(error => console.error("Es gab einen Fehler beim Hinzufügen eines neuen Produkts:", error));
    };

    const handleDeleteProduct = (productId) => {
        axios.delete(`${config.apiUrl}/products.php?id=${productId}`)
            .then(() => {
                setProducts(products.filter(product => product.id !== productId));
            })
            .catch(error => console.error("Es gab einen Fehler beim Löschen des Produkts:", error));
    };

    return (
        <div className="container">
            <h2>Woche</h2>
            <h3>Geplante Gerichte</h3>
            <ul className="list-group">
                {meals.map(meal => (
                    <li key={meal.day} className="list-group-item">
                        <span>{meal.day}: {meal.meal}</span>
                    </li>
                ))}
            </ul>
            <h3>Einzukaufende Produkte</h3>
            <ul className="list-group">
                {products.map(product => (
                    <li key={product.id} className="list-group-item">
                        <span>{product.product_name} ({product.quantity})</span>
                        <button className="delete-button" onClick={() => handleDeleteProduct(product.id)}>✖</button>
                    </li>
                ))}
            </ul>
            <div className="input-divider"></div>
            <div className="input-group mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Produktname"
                    value={newProduct.product_name}
                    onChange={(e) => setNewProduct({ ...newProduct, product_name: e.target.value })}
                />
                <input
                    type="text"
                    className="form-control"
                    placeholder="Menge"
                    value={newProduct.quantity}
                    onChange={(e) => setNewProduct({ ...newProduct, quantity: e.target.value })}
                />
                <button className="btn btn-primary" onClick={handleAddProduct}>Produkt hinzufügen</button>
            </div>
            <button className="btn btn-secondary mt-3" onClick={onEditWeek}>
                Woche bearbeiten
            </button>
        </div>
    );
};

export default WeekView;
